<template>
  <!--微信支付点金计划------------回调-商家小票-->
  <div class="main-page">
    <div class="mch-info">
      <div class="logo-wrapper"><img :src="logo" class="logo" /></div>
      <div class="name">{{ nickname }}</div>
    </div>
    <div class="order-info">
      <div class="item">
        <div class="label">活动名称：</div>
        <div class="value">{{ orderData.activityTitle }}</div>
      </div>
      <div class="item">
        <div class="label">实付金额：</div>
        <div class="value price">{{ orderData.consumeMoney | formatMoney }}</div>
      </div>
    </div>
    <div class="operate-wrapper">
      <van-button type="primary" @click="toOrderDetail">查看订单详情</van-button>
      <van-button v-if="activityType === 1" type="danger" class="key-point" @click="toGroupOnDetail">邀请好友拼团</van-button>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'

export default {
  data() {
    return {
      subMchId: '',
      orderNum: '',
      checkCode: '',
      md5Check: false,
      appId: '',
      orderUrl: '', // 订单详情
      groupOnUrl: '', // 团购详情
      logo: '',
      nickname: '',
      orderData: {},
      activityType: 0 // 活动分类：0：分销：1：团购
    }
  },
  created() {
    this.subMchId = this.$route.query.sub_mch_id
    this.orderNum = this.$route.query.out_trade_no
    this.checkCode = this.$route.query.check_code
  },
  mounted() {
    this.loadGoloPlanJs()
    this.checkMd5()
  },
  methods: {
    // 加载点金计划js
    loadGoloPlanJs() {
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.src = 'https://wx.gtimg.com/pay_h5/goldplan/js/jgoldplan-1.0.0.js'
      document.body.appendChild(s)
    },
    // 初始化商家小票
    initTicket() {
      let mchData = { action: 'onIframeReady', displayStyle: 'SHOW_CUSTOM_PAGE' }
      let postData = JSON.stringify(mchData)
      parent.postMessage(postData, 'https://payapp.weixin.qq.com')
    },
    checkMd5() {
      request
        .request({
          url: '/api/wxcompay/checkMd5',
          method: 'get',
          params: {
            url: encodeURIComponent(window.origin + this.$route.path),
            mchId: this.subMchId,
            orderNum: this.orderNum,
            checkCode: this.checkCode
          }
        })
        .then(res => {
          if (res.succ) {
            this.md5Check = true
            // 订单信息
            this.orderData = res.data.item
            this.activityType = res.data.item.activityType
            // 授权方信息
            let wxAuthorizer = res.data.wxAuthorizer
            this.logo = wxAuthorizer.logo
            this.nickname = wxAuthorizer.nickname
            // 构造跳转到订单详情的url
            this.orderUrl = window.origin + `/${wxAuthorizer.appId}/ucenter/order/detail/${this.orderData.orderNum}`
            if (this.activityType === 1) {
              // 团购订单，跳转到团购详情
              this.groupOnUrl = window.origin + `/${wxAuthorizer.appId}/groupOn/join/${this.orderData.activityId}/${this.orderData.teamId}`
            }
            this.initTicket()
          }
        })
    },
    toOrderDetail() {
      // 跳转到订单详情
      var mchData = {
        action: 'jumpOut',
        jumpOutUrl: this.orderUrl // 跳转的页面
      }
      var postData = JSON.stringify(mchData)
      parent.postMessage(postData, 'https://payapp.weixin.qq.com')
      parent.close()
    },
    toGroupOnDetail() {
      // 跳转到团购详情
      var mchData = {
        action: 'jumpOut',
        jumpOutUrl: this.groupOnUrl // 跳转的页面
      }
      var postData = JSON.stringify(mchData)
      parent.postMessage(postData, 'https://payapp.weixin.qq.com')
      parent.close()
    }
  }
}
</script>
<style lang="less" scoped>
.main-page {
  height: 600px;
  background-color: #fff;
  padding-top: 20px;
}
.mch-info {
  .logo-wrapper {
    margin: 10px;
    img.logo {
      border: 1px solid #999;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: block;
      margin: 0 auto;
    }
  }
  .name {
    text-align: center;
    font-size: 24px;
    line-height: 36px;
    height: 36px;
    font-weight: bold;
  }
}
.order-info {
  margin: 20px 0;
  .item {
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      flex: 1;
      text-align: left;
      padding-left: 10px;
    }
    .value {
      flex: 2;
      text-align: right;
      padding-right: 10px;
      &.price {
        color: red;
      }
    }
  }
}
.operate-wrapper {
  margin: 20px;
  height: 40px;
  display: flex;
  justify-content: space-around;
  line-height: 40px;
  margin: 0 auto;
  text-align: center;
  .key-point {
    font-size: 0.6rem;
  }
}
</style>

